body {
    overflow-x: hidden;
    height: 100%;
    font-family: $primary-font-family;
}

a {
    text-decoration: none;
    color: #ffffff;

    &:hover {
        text-decoration: none;
        color: #808080;
    }
}

ul {
    list-style: none;
}

.row {
    margin: auto;
}

.modal-backdrop {
    z-index: 0;
}

.preloader {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background: rgba(255, 255, 255, 0.8);
    justify-content: center;
    align-items: center;
    z-index: 99;
    transition: all 0.5s ease;
}

.preloader.inactive {
    opacity: 0;
    z-index: 0;
    transition: all 0.5s ease;
}

.modal-dialog {
    // margin: 11.75rem auto;
    font-family: 'Open Sans';

    .modal-header, .modal-footer {
        border: none;
    }

    .modal-submit {
        width: 70%;
    }
}

.modal-content {
    input {
        padding: 1em 2em;
    }

    .md-form {
        max-width: 80%;
        margin: auto;

        input[name="ffscountry"] {
            padding: 0.5em 1em;
        }
    }

    #interest {
        padding: 0 2em;
    }

    select.form-control {
        padding: 0.375rem 2em;
    }
}

.icon-bar {
    position: fixed;
    top: 50%;
    right: 2.5%;
    transform: translateY(-50%);
    z-index: 10;
    padding: 0;

    li {
        margin: 15px 0;


        &:hover {
            img {
                animation: 1.5s linear infinite alternate;
            }

            .icon-3 {
                border-radius: 0%;
            }
        }
    }
}

.img-cover {
    width: 100%;
    object-fit: cover;
}

#section-slogan {
    width: 100%;
    background: url('../img/background-grey-new.jpg') no-repeat left bottom;
    background-size: cover;
    background-attachment: fixed;
    align-items: center;
    text-align: center;

    @include on-desktop {
        margin-bottom: -10em;
    }

    .main-logo {
        width: 100%;
        top: 0;
        
        img {
            margin-bottom: 2em;
            margin-top: 3em;
    
            @include until-mobile {
                margin: 0;
                padding: 2em 0;
            }
        }
    }

    .slogan-wrapper {
        height: 150px;
    }

    .slogan {
        transition: $easeOutExpo;

        h3 {
            color: #ffffff;
            text-shadow: 2.5px 2.5px black;
        }

        @include until-mobile {
            top: 15em;
        }
    }

    .tm {
        letter-spacing: 0;
        vertical-align: super;
        font-weight: 100;
        font-size: 0.5em;
    }

    #logo-intro-wrapper {
        top: 0;
    }

    .container-intro {
        color: white;

        @include until-mobile {
            margin-top: 2em;
        }

        h3 {
            color: white;
            text-shadow: 2.5px 2.5px black;
        }

        p {
            margin: auto;
            @include until-mobile {
                font-size: 0.75em;
            }

            @include until-mobile-sm {
                font-size: 0.55em;
            }
        }
    }

    .group-photo {
        width: 100%;
        max-width: 1254px;
        z-index: 5;
        text-align: center;
        padding: 6em 0;
        position: relative;
        margin: auto;

        @include until-tablet {
            padding: 3em 0;
        }

        @include until-mobile {
            max-width: 500px;
        }

        @include until-mobile-sm {
            max-width: 300px;
        }

        .group-container img {
            width: 100%;

            @include until-mobile {
                padding: 0;
            }
        }

        .group_desktop {
            @include until-mobile {
                display: none;
            }
        }

        .group_mobile {
            display: none;
            
            @include until-mobile {
                display: block;
                margin: -2em auto 0;
            }
        }
    }
}

#section-carousel-title{
    .white-gradient {
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,1) 2%, rgba(255,255,255,1) 4%, rgba(255,255,255,0.15730042016806722) 9%);
        height: 10em;
        width: 100%;

        @include until-tablet {
            height: 6em;
        }

        @include until-mobile {
            height: 3em;
        }

        @include until-mobile-sm {
            height: 1em;
        }
    }
    background: url('../img/people_bg-new.jpg') no-repeat left center;
    background-size: cover;
    position: relative;
    padding-top: 10em;

    @include until-tablet {
        padding-top: 6em;
    }

    @include until-mobile {
        padding-top: 3em;
    }

    @include until-mobile-sm {
        padding-top: 1em;
    }

    .people-title {
        padding: 2em;
        text-align: center;

        @include until-mobile {
            padding: 2em 0;
        }

        p {
            margin: auto;
        }
    }
}

#slider-container {

    background-color: #ffffff;
    position: relative;
    z-index: 15;

    .swiper-inner {
        width: 100%;
        position: relative;
    }
    
    .swiper-bg {
        height: 100%;
        background-size: cover;
        background-position: top center;
        background: no-repeat;
        position: relative;

        img {
            max-height: 450px;
        }
    }
    
    .swiper-bg > h4 {
        position: absolute;
        bottom: 1em;
        left: 0;
        right: 0;
        text-align: center;
        letter-spacing: 0.09em;
    }

    .swiper-bg > h5 {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0.3em;
        font-weight: 500;
    }

    .main-picture {
        width: auto;
        position: relative;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .rectangle {
        float: right;
        width: 15%;

        @include until-mobile {
            width: 40vw;
            max-height: 40px;
        }
    }
}
    
#section-purpose {
    text-align: center;
    position: relative;

    .purpose-container {
        background-color: #ffffff;
        padding-top: 3rem;
        margin-top: 3em;

        @include until-mobile-sm {
            padding: 0;
        }
    }

    #purpose-description {
        font-family: 'Open Sans Light';
        font-size: 20px;
    }

    .purpose-home {
        width: 100%;
        height: 45vh;
        max-width: 1440px;
        margin: auto;
    }

    .purpose-info {
        display: flex;
        width: 100%;
        position: relative;
        justify-content: center;
        overflow: hidden;
        z-index: 5;

        @include until-mobile {
            margin: auto;
        }

        .purpose-wrapper {
            @include until-desktop {
                max-width: 400px;
                max-height: 400px;
            }

            @include until-mobile-sm {
                padding: 0;
            }
        }

        .purpose-inner {
            position: relative;
            display: inline-block;
            width: 100%;
            height: 550px;
            max-height: 400px;
            overflow: hidden;
            transition: all .4s cubic-bezier(.165,.84,.44,1);
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

            // .text-modal {
            //     display: none;
            //     visibility: hidden;
            // }

            &:hover {
                box-shadow: 0 5px 15px rgba(0,0,0,.5);
                transform: translateY(-3px);


                // .text-modal {
                //     display: block;
                //     visibility: visible;
                // }
            }
        }

        .purpose {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: top center;
            background: no-repeat;
            position: relative;
            transition: all $easeOutExpo 0.7s;
        }

        .purpose-bg {
            background-color: black;
            background-size: cover;
            text-align: left;

            .purpose-text {
                padding: 3rem 2rem 1rem;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1;
                transform: translateY(calc(100% - 6.5rem));
                transition: all $easeOutExpo 0.7s;
            
                p {
                    color: #ffffff;
                    font-size: 0.9rem;
                    padding-bottom: 1rem;
                    opacity: 0;
                    transform: translateY(1rem);
                    transition: all $easeOutExpo 1s;
                    transition-delay: 0.15s;
                    padding: 0;
                    cursor: pointer;
                    visibility: hidden;

                    &:hover {
                        transition: all 0.3s;
                        opacity: 1;
                    }

                    .modal-circle {
                        border-radius: 50%;
                        width: 24px;
                        height: 26px;
                        padding: 2px 6.5px;
                        border: thin solid #ffff;
                        color: white;
                        text-align: center;
                        cursor: pointer;

                        &:hover {
                            border: thin solid $primary-color;
                        }

                        @include until-mobile {
                            padding: 1px 5px;
                        }
                    }
                }
            
                @include until-mobile {
                    p {
                        font-size: 0.75rem;
                        padding-bottom: 1em;
                    }
                }
    
                .purpose-text-title {
                    font-size: 1.5rem;
                    font-weight: 500;
                    text-transform: uppercase;
                
                    @include until-mobile {
                        font-size: 2rem;
                    }
                }
            }
        }
        //Hover States
        .purpose-inner:hover .purpose-bg {
            transform: scale(1.1);
            transition: 1s;
        }

        .purpose-inner:hover .purpose-text {
            transform: translateY(0);

        }

        @include until-mobile {
            .purpose-inner:hover .purpose-text {
                transform: translateY(-10%);
            }
        }

        .purpose-inner:hover p {
            opacity: 0.8;
            transform: translateY(0);
            visibility: visible;
            display: block;
        }
    }

    .purpose-backdrop {
        position: absolute;
        right: 0;
        z-index: 1;

        @include on-desktop {
            bottom: -3em;
            height: 100%;
        }

        @include until-mobile {
            right: 0;
            bottom: 0;
            
        }
    }
}

.rectangle-space {
    position: relative;
    width: 15vw;
    height: 2em;
    z-index: 10;
    background-color: #f2f2f2;

    @include until-mobile {
        width: 40vw;
    }
}

#section-client {
    background-color: #f2f2f2;
    position: relative;
    z-index: 5;

    .client-wrapper {
        margin-top: 3em;

        @include until-mobile {
            padding: 0;
            margin-top: 0;
        }
    }

    .client-title p {
        margin: auto;
    }

    .region {
        display: inline-block;
        width: 100%;

        .region-tab {
            padding: 0 2em;
            font-family: 'Open Sans';
            font-weight: 700;
            font-size: 1em;

            @include until-tablet {
                font-size: 0.75em;
            }

            @include until-mobile {
                font-size: 0.7em;
                padding: 0;
            }
        }
    }

    .client-row {
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1em;
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(200px,1fr));

        @include until-mobile {
            grid-template-columns: repeat(auto-fill,minmax(150px,1fr));
        }

        img {
            opacity: 0.4;
            margin-bottom: 1.75em;
            padding: 0 2em;
            max-width: 100%;
            justify-content: space-between;

            &:hover {
                opacity: 1;
            }

            @include until-mobile {
                margin-bottom: 1em;
                padding: 0 2em;
            }

            @include until-tablet {
                padding: 0 1em;
            }
        }

        @include on-desktop {
            .special-icon {
                padding: 0 2.5em;
            }
        }

        @include until-mobile {
            .square-icons {
                padding: 1em 4em;
            }

            .word-icons {
                padding: 1em 2em;
            }
        }

        @include until-mobile-sm {
            .square-icons {
                padding: 1em 3em;
            }
        }
    }

    .south-region {
        justify-content: center;

        img {
            padding: 0 3em;
        }
    }
}

#section-awards {
    .awards-row {
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1em;
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(250px,1fr));

        @include until-mobile {
            grid-template-columns: repeat(auto-fill,minmax(150px,1fr));
        }

        img {
            // opacity: 0.4;
            margin-bottom: 1.75em;
            padding: 0 2em;
            max-width: 100%;
            justify-content: space-between;

            // &:hover {
            //     opacity: 1;
            // }

            @include until-mobile {
                margin-bottom: 1em;
                padding: 0 2em;
            }

            @include until-tablet {
                padding: 0 1em;
            }
        }

        @include on-desktop {
            .special-icon {
                padding: 0 2.5em;
            }
        }

        @include until-mobile {
            .square-icons {
                padding: 1em 4em;
            }

            .word-icons {
                padding: 1em 2em;
            }
        }

        @include until-mobile-sm {
            .square-icons {
                padding: 1em 3em;
            }
        }
    }
}

#section-highlight {
    .container-fluid {
        @include until-desktop {
            padding-left: 0;
        }
    }
    .highlight-main {
        padding-right: 0;
        padding-left: 1.5rem;

        @include until-desktop {
            padding-left: 0;
        }
    }

    .highlights {
        padding-right: 3vw;
        padding-left: 4vw;
    }

    .highlight-wrapper {
        padding-right: 0;
        padding-left: 3rem;

        @include until-desktop {
            padding: 0;
        }
    }

    .highlight-backdrop {
        position: absolute;
        left: -25em;
        z-index: -1;

        @include on-tablet {
            bottom: -3em;
        }

        @include until-mobile {
            display: none;
        }
    }
}

#section-join-us {
    margin-bottom: 5em;

    .video-display {
        position: relative;

        .video-thumb {
            height: auto;
            max-width: 300px;
            width: auto;
            border: none;
            box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.75);
            margin: 0 1.3em;
            text-align: center;

            img {
                max-width: 150px;
                width: 100px;
                height: 90px;
                overflow: hidden;

                @include until-mobile {
                    height: 60px;
                }

                @include until-mobile-sm {
                    max-width: 80px;
                    height: 50px;
                }
            }

            &:hover {
                opacity: 1;
            }

            span {
                font-size: 0.65em;
            }
        }

        .join-us-bg {
            width: 100%;
            margin: auto;
            object-fit: cover;

            @include on-desktop {
                height: 800px;
            }

            @include until-tablet {
                height: 450px;
            }

            @include until-mobile {
                height: 400px;
            }
        }

        .play-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            right: 50%;
            bottom: 50%;
            transform: translate(-50%, -100%);

            @include until-tablet {
                max-height: 100px;
            }

            @include until-mobile {
                max-height: 50px;
            }
        }

        .video-text-wrapper {
            position: absolute;
            bottom: 16em;
            z-index: 15;
            text-align: center;
            width: 100%;

            @include until-tablet {
                bottom: 8em;
            }

            @include until-mobile {
                bottom: 5em;
                font-size: 1rem;
            }

            @include until-mobile-sm {
                bottom: 5em;
            }
        }

        .video-text {
            width: 100%;

            h2 {
                font-weight: 600;
                padding-bottom: 1rem;

                @include until-mobile-sm {
                    font-size: 1em;
                }
            }

            p {
                width: 30.5%;
                color: #ffffff;
                margin: auto;

                @include on-desktop {
                    width: 50.5%;
                }

                @include until-tablet {
                    width: 50.5%;
                }

                @include until-mobile {
                    width: 100%;
                    margin: 0;
                    padding: 0 1rem;
                }

                @include until-mobile-sm {
                    font-size: 0.6em;
                }
            }
        }
    }

    .benefit {
        margin-bottom: 5em;

        @include on-desktop{
            position: relative;
            margin-top: -5em;
        }

        @include until-tablet {
            margin-top: 3em;
        }
    }

    .benefit-main {
        display: flex;
        width: 100%;
        position: relative;
        justify-content: center;
        padding: 0 10rem;

        @include until-desktop {
            padding: 0 8rem;
        }

        @include until-mobile {
            padding: 0 2rem;
        }
    }

    .benefit-inner {
        width: 100%;
        height: 100%;
        max-height: 285px;
        overflow: hidden;
        max-width: 350px;
        margin: auto;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

        @include until-desktop {
            max-height: 250px;
        }
    }

    .benefit-bg {
        background-color: black;
        background-size: cover;
        width: 100%;
        height: 100%;
        position: relative;

        p {
            color: $white;
            text-align: center;
            font-family: 'Open Sans';
            font-weight: 700;
            font-size: 0.875em;
            letter-spacing: 0.05em;
            width: 100%;
            padding: 0 2rem;

            @include until-desktop {
                font-size: 0.65em;                
            }
        }
    }

    .inner-wrap {
        text-align: center;
        width: 100%;
        height: 100%;
        padding: 4rem 0;
    }

    .benefit-icon {
        margin-bottom: 0.5em;

        @include until-desktop {
            max-height: 80px;
        }
    }
}

#submit-form, #recruitment-form {
    
    .submit-title {
        text-align: center;
        max-width: 80%;
        margin: auto;
    
        p {
            font-family: 'Open Sans';
            font-weight: 300;
            margin: auto;
        }
    }

    .form-control {
        padding: 1.5em 1em;
        border-radius: 0;

        ::placeholder {
            color: #202020;
        }
    }

    select, option, select.form-control {
        text-transform: uppercase;
        color: #202020;
        padding: 0 1em;
        height: 100%;
        max-height: calc(1.5em + 0.75rem + 14px);
    }

    .invalid-field {
        text-align: left;
    }

    .button {
        padding: 0 0.8em;
    }
}

.submit-button {
    background-color: #2F2F2F;
    color: $white;
    padding: 1em;
    text-align: center;

    .submit-text {
        font-family: 'Open Sans';
        font-weight: 500;
        letter-spacing: 0.04em;
        font-size: 0.875em;
    }

    &:hover {
        background-color: #f2f2f2;
        color: black;
    }

    &:active {
        margin: 6px 10px 2px 10px;
    }
}

#thank-you {
    background: url('../img/background-grey.jpg') no-repeat left bottom;
    background-size: cover;
    background-attachment: fixed;
    align-items: center;
}