.carousel {
    @include until-desktop {
        max-width: 1000px;
        margin: auto;
    }

    @include until-tablet {
        padding: 0;
    }
}

.carousel-nav {
    display: flex;
    position: absolute;
    bottom: 0;
    background: #ffffff;
    z-index: 10;

    @include until-tablet {
        width: 100%;
    }
}

.carousel-prev, .carousel-next {
    align-items: center;
    justify-content: center;
    color: #ffffff;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease;
    z-index: 1;
    padding: 2rem 2.5rem;

    @include until-mobile {
        padding: 0rem 1rem;
    }
}

.carousel-control-prev-icon {
    background-image: url('../img/highlights/icon-left.svg');
}

.carousel-control-next-icon {
    background-image: url('../img/highlights/icon-right.svg');
}

.nav-arrow {
    padding: 1rem;

    @include until-tablet {
        width: 100%;
    }

    @include until-mobile {
        padding: 0.5rem;
    }
}

.carousel--caption {
    position: absolute;
    bottom: 12%;
    z-index: 10;
    padding: 0 5rem;
    color: #ffffff;
    text-align: left;

    h1 {
        font-size: 3em;
        text-shadow: none;

        @include until-tablet {
            color: #000000;
            text-shadow: none;
        }

        @include until-mobile {
            font-size: 1.75em;
        }
    }

    p {
        padding-left: 5px;

        @include until-mobile {
            font-size: 0.75em;
        }
    }

    @include until-tablet {
        position: relative;
        color: #000000;
        padding: 3rem 5rem;
    }

    @include until-mobile {
        padding: 2rem 1rem 3rem;
    }
}

.carousel-image {
    
    @include until-tablet {
        width: 100%;
    }

    .view img {
        width: 100%;
    }
}

.carousel-image img {
    @include until-desktop {
        width: 100%;
        max-height: 700px;
    }

    @include until-tablet {
        max-height: 800px;
    }
}

.mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-attachment: fixed;
}

.rgba-black-slight, .rgba-black-slight:after {
    background-color: rgba(0,0,0,0.2);
}

.carousel-indicators {
    bottom: 8em;

    @include until-tablet {
        bottom: 0;
    }
    
    @include until-mobile {
        max-height: 55px;
        margin-bottom: 0.3em;
    }

    @include until-mobile-sm {
        max-height: 50px;
        margin: 0;
        bottom: 0;
    }
}

//Hover States

.carousel-prev:hover, .carousel-next:hover {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}

.nav-arrow:hover {
    background-color: #f2f2f2;
}