.recruitment-container {
    
    h4, h5 {
        text-shadow: none;
        color: $primary-color;
    }

    .form-group {
        border: solid 1px $primary-color;
        border-radius: 5px;

        h5 {
            padding: 1rem 1.5rem;
            border-bottom: solid 1px $primary-color;
            background-color: #f2f2f2;
            border-radius: 5px;
        }

        .form-row {
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 0.5rem;
        }
        
        label, input[type=file] {
            font-size: 1rem;
        }
    }

    .mandatory {
        color: red;
    }
}