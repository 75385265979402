@mixin circle($circleSize) {
  width: 10px;
  height: 10px;
  border-radius: $circleSize/2;

  @include until-mobile-sm {
    width: 5px;
    height: 5px;
  }
}

.pulse-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.pulse-box {
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;

    .pulse-name {
        // color: #ffffff;
        opacity: 0;
        z-index: -1;
        margin-left: 1em;
        margin-bottom: 0;

        @include until-tablet {
          max-width: 130px;
        }

        @include until-mobile {
          max-width: 85px;
          margin-left: 5px;
        }

        @include until-mobile-sm {
          max-width: 50px;
          margin-left: 2px;
        }
    }
}

.first-pulse {
    position: absolute;
    left: 5.5%;
    top: 13%;

    @include until-tablet {
      top: 15%;
      left: 5.5%;
    }

    @include until-mobile {
      top: 13%;
      left: 10.5%;
    }

    @include until-mobile-sm {
      top: 12%;
    }
}

.second-pulse {
    position: absolute;
    left: 17.5%;
    top: 13%;

    @include until-tablet {
      left: 17.5%;
      top: 15%;
    }

    @include until-mobile {
      left: 28%;
      top: 10%;
      flex-direction: row-reverse;

      .pulse-name {
        max-width: 60px;
        margin-left: 0;
        margin-right: 5px;
      }
    }

    @include until-mobile-sm {
      top: 9.5%;
      left: 23%;
    }
}

.third-pulse {
    position: absolute;
    left: 27.5%;
    top: 16%;
    
    @include until-tablet {
      top: 17%;
      left: 28%;
    }

    @include until-mobile {
      left: 47%;
      top: 12%;
      flex-direction: row-reverse;
    }

    @include until-mobile-sm {
      left: 47%;
      top: 11%;
      
      .pulse-name {
        margin-left: 0;
        margin-right: 2px;
      }
    }
}

.fourth-pulse {
    position: absolute;
    left: 35%;
    top: 14%;

    @include until-tablet {
      left: 40%;
      top: 16%;
    }

    @include until-mobile {
      left: 76%;
      top: 10.5%;
    }

    @include until-mobile-sm {
      left: 73%;
      top: 10.5%;
      flex-direction: row-reverse;
    }
}

.fifth-pulse {
    position: absolute;
    left: 50%;
    top: 15%;

    @include until-tablet {
      left: 50%;
      top: 15%;
    }

    @include until-mobile {
      left: 26%;
      top: 40%;
    }

    @include until-mobile-sm {
      left: 27%;
      top: 38%;
    }
}

.sixth-pulse {
    position: absolute;
    left: 50%;
    top: 17%;
    flex-direction: row-reverse;
    
    .pulse-name {
      margin-left: 0;
      margin-right: 1em;
    }

    @include until-tablet {
      left: 50%;
      top: 18%;
    }

    @include until-mobile {
      left: 50%;
      top: 40%;

      .pulse-name {
        margin-left: 0;
        margin-right: 5px;
      }
    }

    @include until-mobile-sm {
      left: 54%;
      top: 38%;
    }
}

.seventh-pulse {
    position: absolute;
    left: 64%;
    top: 16.5%;

    @include until-tablet {
      left: 64%;
      top: 18%;
    }

    @include until-mobile {
      left: 10%;
      top: 28.5%;
    }

    @include until-mobile-sm {
      left: 10%;
      top: 28%;
    }
}

.eighth-pulse {
    position: absolute;
    left: 72%;
    top: 18.5%;
    // flex-direction: row-reverse;

    @include until-tablet {
      left: 72%;
      top: 22.5%;
    }

    @include until-mobile {
      left: 23%;
      top: 24.5%;
      flex-direction: row-reverse;
    }

    @include until-mobile-sm {
      top: 22.5%;
      left: 25%;
    }
}

.ninth-pulse {
  position: absolute;
  left: 82%;
  top: 14.5%;
  // flex-direction: row-reverse;

  @include until-tablet {
    left: 82%;
    top: 15.5%;
  }

  @include until-mobile {
    left: 55%;
    top: 24.5%;
  }

  @include until-mobile-sm {
    left: 55%;
    top: 22.5%;
  }
}

.tenth-pulse {
  position: absolute;
  left: 86%;
  top: 16.5%;
  flex-direction: row-reverse;

  .pulse-name {
    margin-left: 0;
    margin-right: 1em;
  }

  @include until-tablet {
    left: 84%;
    top: 18.5%;
  }

  @include until-mobile {
    right: 12%;
    left: initial;
    top: 26.5%;

    .pulse-name {
      margin-right: 5px;
    }
  }

  @include until-mobile-sm {
    right: 11%;
    top: 24.5%;

    
  }
}

.pulse-name {
  transition: ease-out 0.5s;
}

.pulse-css {
    @include circle(50px);
    display: flex;
    background: white;
    position: relative;
    &:before,
    &:after {
      content: "";
      @include circle(50px);
      background-color: white;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      transform: scale(0.5);
      transform-origin: center center;
      animation: pulse-me 3s linear infinite;
    }
    &:after {
      animation-delay: 2s;
    }
  }
  
  @keyframes pulse-me {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    50% {
      opacity: 0.1;
    }
    70% {
      opacity: 0.09;
    }
    100% {
      transform: scale(5);
      opacity: 0;
    }
}

.pulse-box {
  .active {
    opacity: 1;
    transition: ease-in 0.5s;
    z-index: 10;
  }
}

// .pulse-box {
//   .pulse-css:hover {
//       .pulse-name {
//           opacity: 1;
//           transition: ease-in 0.5s;
//       }
//   }
// }