.nav-tabs {
    display: flex;
    border-bottom: none;
    
    .nav-item {
        display: inline-flex;
        border: solid 1px black;
        text-decoration: none;
        width: 100%;
        max-width: 250px;
        padding: 0;

        a {
            color: #252525;
            text-decoration: none;
            padding: 0.5em 3em;   

            @include until-mobile {
                padding: 0.5em 0;
            }
        }

        @include until-mobile {
            max-width: 170px;
        }
    }

    .nav-link {
        width: 100%;
    }

    .nav-item:not(:first-child) {
        margin-left: -1px;
    }

    .nav-link.active {
        background-color: #2f2f2f;
        color: $white;
        border: none !important;
        border-radius: 0;
    }
}