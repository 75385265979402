footer {
    position: relative;
    z-index: 10;
}

footer > * {
    color: $white;
}

@keyframes bounce {
    0%      { transform: translateY(0); }
    50%     { transform: translateY(-5px); }
    100%    { transform: translateY(0); }
}

.backtotop {
    position: absolute;
    right: 3.5%;
    top: -10%;
    z-index: 5;
    cursor: pointer;

    @include until-mobile {
        top: -5%;
    }

    &:hover {
        animation: bounce 2s infinite ease;
    }
}

.footer-bg {
    background-color: black;
    background-size: cover;
    max-width: 100%;
    position: relative;
}

.footer-wrapper {
    padding: 4em 2em 4em 4em;

    @include until-mobile {
        padding: 4em 2em 4em 2em;
    }
}

.footer-info {
    width: 100%;

    @include on-tablet {
        display: flex;
    }
}

p, span {
    font-weight: 300;
    font-size: 0.875em;
}

.details {
    font-weight: 500;
    letter-spacing: 0.05em;
}

.copyright {
    font-size: 0.7125em;
    font-weight: 200;
    opacity: 0.6;

    @include until-mobile {
        margin-top: 1em;
        font-size: 0.575em;
    }
}

.footer-logo {
    @include until-mobile {
        text-align: center;
        margin: 2em 0;
    }
}

.logo-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;

    li {
        margin: 15px 0;

        &:hover {
            img {
                border-radius: 50%;
                box-shadow: 0px 0px 9px 4px #808080;
                animation: 1.5s linear infinite alternate;
            }

            .icon-3 {
                border-radius: 0%;
            }
        }
    }
}

.logo {
    @include on-tablet {
        position: absolute;
        right: 3em;
        bottom: 0.75em;
    }

}
