.swiper-slide {
    // height: 80%;
    width: 100%;
}

.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    // top: 50%;
    top: 60%;
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color, var(--swiper-theme-color));
    transition: all $easeOutExpo 0.7s;
}

.swiper-button-prev, .swiper-button-next {
    
    &:focus {
        outline: none;
    }
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    display: inline;
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    display: inline;
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    left: auto;
    right: auto;
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    right: auto;
    left: auto;
}

.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after, 
.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
    content: '';
    display: inline-block;
    width: 2em;
    height: 2em;
    bottom: 1.5em;
    position: relative;
}

.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
    background: url('../img/buttons/back_new.svg') no-repeat;
    background-size: contain;
}

.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
    background: url('../img/buttons/next_new.svg') no-repeat;
    background-size: contain;
}

//Hover States

.swiper-button-prev:hover, .swiper-button-next:hover {
    transform: scale(1.1);
    transition: 1s;
}