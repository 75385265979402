@font-face {
    font-family: 'Open Sans Bold';
    font-weight: 700;
    font-style: normal;
    src: url('../fonts/OpenSans-Bold.ttf') format('truetype')
}

@font-face {
    font-family: 'Open Sans Light';
    font-weight: 300;
    font-style: normal;
    src: url('../fonts/OpenSans-Light.ttf') format('truetype')
}

@font-face {
    font-family: 'Sofia Pro';
    font-weight: 900;
    font-style: normal;
    src: url('../fonts/SofiaProBlack.otf') format('opentype'),
    url('../fonts/SofiaProBlack.otf') format('opentype'),
    url('../fonts/SofiaProBlack.woff2') format('woff2');
}

@font-face {
    font-family: 'Sofia Pro';
    font-weight: 700;
    font-style: normal;
    src: url('../fonts/SofiaProBold.otf') format('opentype'),
    url('../fonts/SofiaProBold.otf') format('opentype'),
    url('../fonts/SofiaProBold.woff2') format('woff2');
}

@font-face {
    font-family: 'Sofia Pro';
    font-weight: 200;
    font-style: normal;
    src: url('../fonts/SofiaProExtraLight.otf') format('opentype'),
    url('../fonts/SofiaProExtraLight.otf') format('opentype'),
    url('../fonts/SofiaProExtraLight.woff2') format('woff2');
}

@font-face {
    font-family: 'Sofia Pro Light';
    font-weight: 300;
    font-style: normal;
    src: url('../fonts/SofiaProLight.otf') format('opentype'),
    url('../fonts/SofiaProLight.otf') format('opentype'),
    url('../fonts/SofiaProLight.woff2') format('woff2');
}

@font-face {
    font-family: 'Sofia Pro';
    font-weight: 500;
    font-style: normal;
    src: url('../fonts/SofiaProMedium.otf') format('opentype'),
    url('../fonts/SofiaProMedium.otf') format('opentype'),
    url('../fonts/SofiaProMedium.woff2') format('woff2');
}