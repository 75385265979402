body {
    font-weight: normal;
    color: $black;
    font-size: 20px;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Open Sans Bold';
    font-weight: 700;
    color: $white;
    letter-spacing: 0.05em;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    font-size: 8.375em;
    text-shadow: 2.5px 2.5px black;

    @include until-tablet {
        font-size: 5.375em;
    }

    @include until-mobile {
        font-size: 3.375em;
    }
}

h2 {
    font-weight: 300;
    color: $white;
    font-size: 2.75em;
    letter-spacing: 0.1em;

    @include until-tablet {
        font-size: 2.25em;
    }

    @include until-mobile {
        font-size: 1.75em;
        margin-bottom: 0;
    }

    @include until-mobile-sm {
        font-size: 1.65em;
    }
}

h3 {
    color: $black;
    font-size: 3em;

    @include until-tablet {
        font-size: 2em;
    }
    
    @include until-mobile {
        font-size: 1.75em;
        margin-bottom: 0;
    }

    @include until-mobile-sm {
        font-size: 1.5em;
    }
}

h5 {
    color: $white;
    text-shadow: 1px 1px black;
    font-size: 0.85em;

    @include until-desktop {
        font-size: 0.7em;
    }

    @include until-tablet {
        font-size: 0.65em;
    }

    @include until-mobile {
        font-size: 0.65em;
    }

    @include until-mobile-sm {
        font-size: 0.55em;
    }
}
